.alert {
  .fade-visible;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  padding: 15px 0;
  margin-bottom: 18px;
  border: none;
  .border-radius(4px);

  &.alert-error {
    background: @red;
    color: white;
  }

  &.alert-info {
    background: @infoBgColor;
    color: @lightTextColor;
    font-weight: normal !important;
  }
}

.spinny {
  display: inline-block;
  height: 16px;
  width: 16px;

  .transition(all 0.25s ease-in-out);
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  background-image: url('../static/img/spinner_48.gif');
  background-position: 0 0;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}

progress {
  @borderThickness: 1px;
  @outerRadius: 0.1875rem;
  // avoid gap between the border and the inner bar
  @innerRadius: ~'calc(@{outerRadius} - @{borderThickness})';
  appearance: none;
  background: white;
  border: @borderThickness solid @gray9;
  border-radius: @outerRadius;

  &::-webkit-progress-bar {
    background: white;
    border-radius: @innerRadius;
  }
  &::-webkit-progress-value {
    background: @blue4;
    border-top-left-radius: @innerRadius;
    border-bottom-left-radius: @innerRadius;
  }
  &::-moz-progress-bar {
    background: @blue4;
    border-top-left-radius: @innerRadius;
    border-bottom-left-radius: @innerRadius;
  }
}

#darken-overlay {
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: fade(@gray11, 80%);
  display: none;
  &.overlay-visible {
    display: block;
  }
}

.overlay-view {
  .outset-shadow-heavy();
  position: fixed;
  z-index: 1000000;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background: @pageBgColor;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 35px;
  overflow: auto;

  h1 {
    color: @primaryTextColor;
    font-weight: normal;
    margin-bottom: 15px;
  }

  h2 {
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 10px;
    clear: both; // for the topic legend.
    font-size: 22px;
    color: @secondaryTextColor;
  }

  h4 {
    color: @primaryTextColor;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    padding: 0.5em 0;
    font-size: 1.4em;
  }

  .close-view {
    display: block;
    margin: 0;
    margin-right: 0;
    margin-left: auto;
    padding-right: 0;
    padding-top: 0;
    background: none;
    box-shadow: none;
    color: @linkTextColor;
    transition: color 0.2s ease;
    border: none;
    &:hover {
      color: @subtleTextActiveColor;
    }
  }

  p {
    padding-top: 0;
    max-width: 650px;
    line-height: 1.65;
    font-size: 15px;
    color: @primaryTextColor;
  }
}

//
// Form
//

.label {
  font-size: 10.998px;
  font-weight: bold;
  line-height: 14px; // ensure proper line-height if floated
  color: white;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 fade(@gray11, 25%);
  background-color: @gray4;
  padding: 1px 4px 2px;
  .border-radius(3px);
}

input,
button {
  font-size: 16px;
}

fieldset {
  border: none !important;
  background: @containerBgColor;
  .border-radius(5px);
  padding: 25px;
  .borderbox;
  .clearfix;
}

input[type='text'],
input[type='password'] {
  display: block;
  .borderbox;

  border: 1px solid @containerBgColor;
  .border-radius(0.125rem);
  .box-shadow(inset 0 1px 3px fade(@gray11, 30%));

  background: @inputBgColor;
  color: @inputFgColor;
  .placeholder(@fadedTextColor);

  padding: 10px 15px;
  height: 2rem;

  &:focus,
  &:active {
    border: 1px solid fade(@inputBorderHoverColor, 66%) !important;
  }

  &.disabled {
    border: 1px solid @containerBgColor !important;
  }

  &.light {
    background: @containerBgColor;
    border: 1px solid @containerHoverBgColor;

    &.disabled {
      border: 1px solid @containerHoverBgColor !important;
    }
  }

  &.error {
    border: 1px solid @red4 !important;
  }
}

.button {
  border: none;
  padding: 13px 20px;
  position: relative;
  .border-radius(2px);
  .button-colors;

  .text {
    position: relative;
    z-index: 100;
  }

  &.green {
    background: @btn-green;
    color: @gray9;
    i {
      color: @green5;
    }

    &:hover {
      background: @green2;
      i {
        color: @green6;
      }
    }
  }
}

.link {
  color: @fadedTextColor;
  text-decoration: none;
}

.subtle-link {
  color: @subtleTextColor;

  &:hover {
    color: @subtleTextHoverColor;
  }

  &:active {
    color: @subtleTextActiveColor;
  }
}

.external-link {
  &:after {
    content: ' \f08e';
    font-family: FontAwesome;
    font-size: 0.75em;
  }
}

a {
  .link;
  color: @linkTextColor;
}

.underline {
  border-bottom: 1px solid black;

  &.underline-gray {
    border-bottom-color: @gray1;
  }

  &.underline-red {
    border-bottom-color: @red4;
  }

  &.underline-blue {
    border-bottom-color: @blue5;
  }
}

// Border Radius with distinction
.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
  -moz-border-top-left-radius: @radius;
  border-top-left-radius: @radius;
}

.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -moz-border-bottom-left-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-top-right-radius(@radius) {
  -webkit-border-top-right-radius: @radius;
  -moz-border-top-right-radius: @radius;
  border-top-right-radius: @radius;
}
.border-bottom-right-radius(@radius) {
  -webkit-border-bottom-right-radius: @radius;
  -moz-border-bottom-right-radius: @radius;
  border-bottom-right-radius: @radius;
}

// Spinner
@keyframes spin {
  0% {
    transform: rotate(-30deg);
  }
  30% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(150deg);
  }
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(330deg);
  }
}

.spinner {
  vertical-align: text-bottom;
  fill: @gray8;

  g {
    transform-origin: center;
    animation: spin 1.75s infinite ease-in-out;
  }
}
