#login {
  .alerts {
    position: relative;
    height: 3rem;

    // Positioning relies on the info alert.
    .alert-info {
      margin-bottom: 30px;
    }

    .alert-error {
      position: absolute;
      top: 0;
      width: 100%;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .center-vertical {
    top: 50%;
    @height: 270px;
    height: @height;
    margin-top: -((@height / 2) + 100px);
  }

  .center-horizontal {
    left: 50%;
    @width: 500px;
    width: @width;
    margin-left: -(@width / 2);
  }

  .content {
    position: absolute;
    .center-vertical;
    .center-horizontal;
  }

  form {
    margin-bottom: 15px;

    input {
      margin-bottom: 10px;
      width: 100%;
    }

    .button {
      width: 135px;
    }
  }

  .btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    .button {
      width: 12rem;
      box-sizing: border-box;
      text-align: center;
    }
  }

  .forgot {
    margin-bottom: 15px;
    text-align: center;

    a {
      .subtle-link;
    }
  }

  .data-use-acknowledgement {
    width: 550px;
    display: flex;
    flex-direction: column;

    .message {
      margin: 1rem 0;
    }

    .button.decline {
      background: none;
      box-shadow: none;
      color: @btn-blue;
      transition: color linear 0.2s;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fade-in 0.2s ease-in-out forwards;
  }

  .fade-in-loading-stripes {
    &:extend(.loading-stripes);
    animation: animate-stripes 2s linear infinite,
      fade-in 0.2s ease-in-out forwards;
  }
}
