#overview {
  // Keep the row of controls at the top of the list from shrinking too much
  min-width: 65rem;

  .header {
    position: relative;

    h2 {
      font-size: 30px;
      color: @gray10;
      font-weight: 300;
      margin-left: 10px;
      display: inline-block;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
  }

  .new-project-link {
    margin-left: auto;
  }

  .radiogroup {
    color: @blue4;
    line-height: 41px;
    font-size: 13px;
    .input-with-label {
      padding-left: 8px;
    }
    label {
      padding-top: 5px;
      cursor: pointer;
    }
    input {
      line-height: 41px;
      cursor: pointer;
    }
  }

  .input-with-inset-icon {
    position: relative;
    margin-right: 1rem;

    > input {
      height: 2.5rem;
      padding: 0 2.5rem 0 0.5rem;
      margin: 0;
      box-sizing: border-box;
      font-size: 1rem;
    }

    > i {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      padding: 0;
      font-size: 1em;
      color: @blue4;
      pointer-events: none;
    }
  }

  #workspace-dropdown {
    width: 250px;
    color: @blue0;
    display: inline;
    margin-right: 1rem;

    i {
      padding-top: 2px;
      padding-right: 7px;
      font-size: 13px;
    }
  }

  #copy-overlay {
    background: @mainBackgroundColor;
    padding: 1rem;
    width: 60ch;
    min-width: 30rem;
    height: auto;
    overflow: visible;
    top: 45%;
    left: 50%;
    margin-right: 50%;
    line-height: 2rem;
    .translate(-50%, -50%);
    p {
      color: @primaryTextColor;
      margin: 20px 0px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    .chosen-select {
      display: block;
      margin-top: 5px;
    }
    p.footer {
      margin: 0;
      text-align: right;
    }
    .button {
      padding: 0 0.5rem;
      line-height: 2rem;
    }
    input {
      width: 100%;
    }
  }

  #copying-text {
    z-index: 1000;
    position: fixed;
    bottom: 10px;
    right: 20px;
    padding: 10px;
    border-radius: 2px;
    background: @pageBgColor;
    .fade-visible;
  }

  .hidden-container {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .rows {
    margin-bottom: 50px;

    .row {
      margin-bottom: 25px;
    }

    .contents {
      position: relative;
      border-radius: 3px;
      background: white;
    }

    a.title,
    span.hidden-title,
    input.title {
      // Align the title and the underlying input
      // in terms of styling, positioning, and font
      // antialiasing and kerning.
      display: inline-block;
      word-break: break-word;
      font-size: 28px;
      font-weight: normal;
      line-height: 30px;
      padding: 10px;
      padding-top: 0;
      margin-left: -10px;
      -webkit-font-smoothing: antialiased;
      -webkit-font-kerning: normal !important;
    }

    input.title {
      display: inline-block;
      border: none !important;
      line-height: 30px !important;
      margin-bottom: 5px !important; //
      padding-top: 5px !important; // For consistency with the bottom
      padding-bottom: 5px !important;
      margin-top: -5px !important; //
      margin-left: -10px !important;
      font-weight: normal !important;
      font-size: 28px !important;
      color: @primaryTextColor !important;
      box-sizing: content-box !important;
      -webkit-font-smoothing: antialiased;
      width: 100%;
    }

    .title-wrapper {
      margin-bottom: 10px;
    }

    a.title {
      display: inline;
      color: @primaryTextColor;
      cursor: default;
      font-weight: 300;
      padding-bottom: 0;

      &.enabled {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        &:active {
          color: @primaryTextColor;
        }
      }
    }

    .description {
      color: @secondaryTextColor;

      margin: 10px 0;
      font-size: 17px;
      line-height: 23px;

      &.editable span {
        cursor: pointer;
      }

      &.missing span {
        &:hover {
          color: @linkTextColor;
        }
        &:active {
          color: @linkTextColor;
        }
      }
    }

    .expanding-textarea {
      position: relative;
      padding: 10px 0;

      textarea,
      pre {
        .box-sizing(border-box);
        border: none;
        color: @secondaryTextColor;
        padding: 0;
        margin: 0;
        width: 100%;

        background: transparent;

        white-space: pre-wrap;
        word-break: normal;
        font-weight: normal;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -webkit-font-kerning: normal !important;
        font-kerning: normal !important;
      }

      textarea {
        position: absolute;
        top: 0;
        left: -5px;
        width: 100%;
        height: 100%;
        padding: 10px 5px;

        resize: none;
        overflow: hidden;
        background: @inputBgColor;
        .box-shadow(inset 0 0 10px fade(@gray11, 18%));
      }

      pre {
        display: block;

        span {
          width: 100%;
          word-break: break-word;
        }
      }
    }

    .edit-button {
      .button;
      font-size: 0.875rem;
      line-height: 1;
      padding: 8px 15px;
      min-width: 30px;
      text-align: center;
      display: inline-block;
      vertical-align: bottom;
    }

    .save-button {
      display: none;
      .button;
    }

    .subtitle {
      display: block;
      font-size: 14px;
      color: @gray4;
      margin-bottom: 20px;
      word-break: break-word;
    }

    .main {
      .clearfix();
      flex: 1;
      margin-right: 0.5rem;
    }

    .status {
      .clearfix();
      max-width: 370px;
      position: relative;
      word-break: break-word;

      .indicator {
        float: right;
        .clearfix();
        @size: 40px;
        height: @size;
        line-height: @size;
        background: @gray0;
        border-radius: 25px;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .light {
          height: @size;
          width: @size;
          background: yellow;
          border-radius: 999px;
          float: left;
          box-sizing: border-box;
          border: 5px solid @gray1;
          display: flex;
          align-items: center;
          justify-content: center;

          &.slate {
            .gradient(top, @gray2, @gray5);
          }
          &.yellow {
            .gradient(top, @yellow2, @yellow5);
          }
          &.green {
            .gradient(top, @green3, @green6);
          }
          &.red {
            .gradient(top, @red2, @red5);
          }
        }

        .content {
          float: left;
          padding: 0 25px 0 10px;
          font-size: 12px;
        }

        .content.centerPadding {
          padding: 0 25px;
        }
      }

      .message {
        clear: both;
        .clearfix();
        float: right;
        width: 150px;
        margin-top: 0.5rem;
        font-size: 13px;
        color: @secondaryTextColor;
      }

      progress {
        height: 0.5rem;
        margin-top: 0.5rem;
        float: right;
        clear: both;
        width: 100%;
      }

      .status-buttons-container {
        width: 100%;
        display: flex;
        gap: .3rem;
        justify-content: flex-end;
      }

      .status-button {
        .button;
        display: inline-block;

        &:not(:last-child) {
          margin-right: 5px;
        }
        font-size: 0.875rem;
        padding: 5px 10px;
        margin-top: 15px;
      }

      .disabledButton {
        background-color: @gray1;
        color: @gray5;
        svg,
        .fa {
          color: @gray5;
        }

        &:hover {
          background-color: @gray1;
          cursor: default;
        }
      }
    }

    .row:hover .delete-button {
      opacity: 1;
    }

    .delete-button {
      display: block;
      position: absolute;
      @radius: 18px;
      @border: 2px;
      right: -@radius;
      top: -@radius;
      @size: 2 * (@radius - @border);
      height: @size;
      width: @size;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      .border-radius(@radius);
      .box-shadow(0 1px 5px fade(@gray11, 40%));
      overflow: hidden;
      border: @border solid transparent;
      background: @red3;
      color: white;
      opacity: 0;

      .x,
      .text {
        pointer-events: none;
      }

      .x {
        position: absolute;
        left: 0.5rem;
        bottom: 0.5rem;
      }

      .text {
        line-height: @size;
        color: white;
        opacity: 0;
        white-space: nowrap;
      }

      & {
        .transition(e('width 0.2s ease-in-out, opacity .2s ease-in'));
      }
      .text {
        .transition(opacity 0.25s ease-in-out);
      }
      .x {
        .transition(all 0.25s ease-in-out);
      }

      &:hover {
        @width: 115px;
        width: @width;
        border-color: transparent;
        .x {
          opacity: 0;
          left: @width / 2;
        }

        .text {
          opacity: 1;
        }
      }

      &:active {
        border-color: @red5;
        background: @red9;
        color: @red0;
      }
    }
  }

  .trademark {
    padding-bottom: 1rem;
    text-align: center;
  }

  .project-button-icon {
    color: @blue4;
  }
}
