// BEGIN Header styles
.btn {
  appearance: none;
  background: white; // Was blue in core-ui
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 3px fade(@gray11, 30%);
  color: white;
  cursor: pointer;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  margin: 0.25em; // Edited from core-ui
  padding: 0 0.5em; // Edited from core-ui
  transition: background-color 0.2s ease;
}
header {
  margin-bottom: 0.5em;
  overflow: visible;
  z-index: 1;

  .logo-img {
    height: 2rem;
    width: auto;
    margin-right: 1.125rem;
  }

  .header-bar {
    background: white;
    height: 3em;
    padding: 0 1.125rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .outset-shadow();

    .header-nav {
      flex: auto;

      nav {
        vertical-align: top;
        display: inline-block;

        ul {
          line-height: 2rem;
          padding: 0 0.5rem;
          margin: 0 2em;

          .nav-link {
            display: inline-block;
            list-style: none;
            margin: 0.25em;

            a {
              .btn;
              background: transparent;
              box-shadow: none;
              color: white;
              font-weight: normal;
              margin: 0;
            }
          }
          .nav-link.selected,
          .nav-link:hover {
            a {
              .btn;
              color: @blue6;
              margin: 0;
              text-decoration: none;
            }
          }
        }
      }
    }

    .header-menu {
      flex: none;

      .menu-title {
        width: 100%;
        color: @gray11;
        background: white;
        font-weight: normal;
        padding: 0;
        border: none;

        &:active {
          color: @gray11;
        }

        a {
          color: @blue4;
        }
      }

      .workspace-links {
        position: relative;
        cursor: pointer;

        .user-menu {
          .outset-shadow-heavy();
          position: absolute;
          box-sizing: border-box;
          right: 0;
          white-space: nowrap;
          padding: 0.5rem 1em;
          margin-top: 0.5em;
          background-color: white;
          border-radius: 0.125em;
          z-index: 100; // TODO is there a better way to do this?

          .menu-divider {
            border: none;
            height: 1px;
            background-color: @gray1;
          }

          .menu-item {
            line-height: 1.25em;
            margin: 0.5em 0;

            .menu-link {
              border: none;
              padding: 0;
              background-color: inherit;
              color: @linkTextColor;

              &:hover {
                color: @fadedTextActiveColor;
              }
              &:active {
                color: @fadedColor;
              }
            }
          }

          &.hidden {
            display: none;
          }
        }
      }
    }
  }
}

.breadcrumb-nav {
  position: relative;
  flex-grow: 1;
  min-width: 0;
}

.breadcrumb-nav__breadcrumbs {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb-nav__breadcrumb {
  min-width: 2rem;
  color: @primaryTextColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  padding-left: 0;

  &:not(:first-child):before {
    content: '';
    height: 1rem;
    border-left: solid 1px @gray5;
    margin: 0 0.5rem;
    transform: skewX(-25deg);
  }
}

.breadcrumb-nav__link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: @btn-blue;
  margin: 0.5rem 0;

  &:not(.breadcrumb-nav__link--active) {
    &:hover {
      color: lighten(@btn-blue, 10%);
      text-decoration: underline;
    }
  }
}

.breadcrumb-nav__link--logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breadcrumb-nav__link--active {
  color: @primaryTextColor;
}
// END Header styles
