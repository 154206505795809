.page-section-box .row {
  margin-left: 0;
}

.rows {
  &.search-active .row:first-child .contents:first-child {
    background: @containerHoverBgColor;
  }

  .contents {
    display: flex;
    flex-direction: row;
    background: white;
    padding: 25px;
    box-shadow: 0 1px 3px fade(@gray11, 40%);

    h3 {
      color: @primaryTextColor;
      font-size: 24px;
      font-weight: normal;
    }

    a.button {
      display: inline-block;
      margin-top: 10px;
      margin-left: 10px;
      float: right;

      &:hover {
        color: white;
        .vertical-gradient(@gray4, @gray5);
      }

      &:active {
        .vertical-gradient(@gray10, @gray7);
      }
    }
  }

  // Borders on the right corners
  .row:first-child .contents {
    .border-top-left-radius(5px);
    .border-top-right-radius(5px);
  }

  .row:last-child .contents {
    .border-bottom-left-radius(5px);
    .border-bottom-right-radius(5px);
  }
}
