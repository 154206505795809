@import '~chosen-js/chosen.min.css';
@import '~normalize.css/normalize.css';
@import '~font-awesome/css/font-awesome.min.css';

/**
 * Luminoso Color Palette
 *
 * Any changes to these colors must also be made in:
 * - main/scripts/styles.js
 * - main/styles/core/variables.styl
 */
@gray0: #f3f4f5;
@gray1: #dde0e2;
@gray2: #c7cdcf;
@gray3: #b1b9bc;
@gray4: #9ba5a9;
@gray5: #859196;
@gray6: #6e7e82;
@gray7: #586a6f;
@gray8: #42565c;
@gray9: #2c4249;
@gray10: #162f36;
@gray11: #001b23;

@purple0: #fcefff;
@purple1: #e3c7f2;
@purple2: #c99fe4;
@purple3: #b077d7;
@purple4: #964fc9;
@purple5: #7d27bc;
@purple6: #6e21aa;
@purple7: #5e1a97;
@purple8: #4f1385;
@purple9: #400d72;
@purple10: #300760;
@purple11: #21004d;

@pink0: #fff0f4;
@pink1: #fbc3dd;
@pink2: #f896c6;
@pink3: #f468ae;
@pink4: #f03b97;
@pink5: #d53387;
@pink6: #b92a76;
@pink7: #9e2266;
@pink8: #831956;
@pink9: #681146;
@pink10: #4c0835;
@pink11: #310025;

@red0: #fff1ed;
@red1: #fac3c0;
@red2: #f59694;
@red3: #ef6867;
@red4: #ea3a3a;
@red5: #d03234;
@red6: #b7292d;
@red7: #9d2127;
@red8: #831920;
@red9: #69111a;
@red10: #500813;
@red11: #36000d;

@orange0: #fff2d9;
@orange1: #fed5a7;
@orange2: #fcb875;
@orange3: #fb9b43;
@orange4: #e2893b;
@orange5: #c87732;
@orange6: #af652a;
@orange7: #965322;
@orange8: #7c4019;
@orange9: #632e11;
@orange10: #491c08;
@orange11: #300a00;

@yellow0: #fff883;
@yellow1: #fce85a;
@yellow2: #f8d830;
@yellow3: #e0c22b;
@yellow4: #c8ac25;
@yellow5: #b19620;
@yellow6: #99801b;
@yellow7: #816b15;
@yellow8: #695510;
@yellow9: #523f0b;
@yellow10: #3a2905;
@yellow11: #221300;

@green0: #ecffdb;
@green1: #daffba;
@green2: #c3ed96;
@green3: #acdc72;
@green4: #95ca4e;
@green5: #85b444;
@green6: #759e3b;
@green7: #658831;
@green8: #557227;
@green9: #455c1d;
@green10: #354613;
@green11: #25300a;

@blue0: #ebf5ff;
@blue1: #b1e2f7;
@blue2: #78d0ef;
@blue3: #3ebde6;
@blue4: #04aade;
@blue5: #0396c2;
@blue6: #0381a6;
@blue7: #026d8a;
@blue8: #02586e;
@blue9: #014452;
@blue10: #012f36;
@blue11: #001b1a;
// End color palette

@pageBgColor: @gray1;
@containerBgColor: white;
@containerDarkerBgColor: @gray1;
@containerHoverBgColor: white;
@containerHoverLighterBgColor: @gray1;
@containerActiveBgColor: @blue2;
@inputBgColor: white;
@inputFgColor: @gray9;
@inputBorderHoverColor: white;

@lightTextColor: @gray7;
@lightTextDarkerColor: @gray8;

@fadedTextColor: @gray8;
@fadedTextHoverColor: @lightTextColor;
@fadedTextActiveColor: @blue2;
@fadedTextLighterColor: @gray7;

@subtleTextColor: @gray8;
@subtleTextHoverColor: @gray9;
@subtleTextActiveColor: @blue2;

@infoBgColor: @containerBgColor;

// Temporarily moving orphaned variables
@color: @blue4;
@fadedColor: @blue5;

////////////////////////////////////////
// Adding in new variables for paint job
// Main Colors
@mainBackgroundColor: white;
@primaryTextColor: @gray9;
@secondaryTextColor: @gray7;
@linkTextColor: @blue4;
@whiteTransparency: fade(white, 40%);
// Core Color Palette
@dkblue: @blue6;
@ltblue: @blue2;
@dkgreen: @green7;
@ltgreen: @green4;
@amber: @yellow2;
@red: @red4;
// Button Colors
@btn-blue: @blue4;
@btn-green: @green1;
@btn-amber: @yellow2;
@btn-red: @red3;

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
    url(../static/fonts/lato/Lato-Light.woff2) format('woff2'),
    url(../static/fonts/lato/Lato-Light.woff) format('woff'),
    url(../static/fonts/lato/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
    url(../static/fonts/lato/Lato-Regular.woff2) format('woff2'),
    url(../static/fonts/lato/Lato-Regular.woff) format('woff'),
    url(../static/fonts/lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url(../static/fonts/lato/Lato-Bold.woff2) format('woff2'),
    url(../static/fonts/lato/Lato-Bold.woff) format('woff'),
    url(../static/fonts/lato/Lato-Bold.ttf) format('truetype');
}

.button-colors {
  color: @gray9;
  background: @blue0;
  transition: background linear 0.2s;
  box-shadow: 0 1px 2px fade(@gray11, 40%);

  &:active,
  &:hover {
    background: @blue1;
  }

  &.disabled,
  &.disabled:active {
    background: @gray0;
    color: @gray5;
  }
}

@import './elements.less';
@import './components/utils.less';
@import './components/header.less';
@import './components/login.less';
@import './components/overview.less';
@import './components/join.less';
@import './rows.less';
@import './chosen-overrides.less';
@import './components/tables.less';
@import './mixins.less';

//
// Section Switching
//
body {
  margin: 0;
  background-color: @mainBackgroundColor;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 18px;
  .font-smoothing(antialiased);

  #sections {
    padding-top: 50px;
    color: @primaryTextColor;
  }
  #ext_login {
    height: 100%;
  }

  // Fullscreen sections
  &.login {
    // Hide overflow below
    overflow: hidden;
  }

  // Fullscreen sections
  #login {
    // Position fullscreenily
    position: absolute;
    z-index: 2;
    top: 48px; // Make room for new nav bar
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

table {
  border-collapse: collapse;
}

ul {
  padding: 0;
  margin: 0 0 9px 25px;
  list-style: disc;
}

p {
  margin: 0 0 9px;
}

select {
  color: @gray11;
}

pre {
  font-family: inherit;
}

// HEADINGS
// --------

h1,
h2,
h3 {
  margin: 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 36px;
  color: inherit;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
  line-height: 27px;
}

.container {
  .clearfix();
  margin: 0 2rem;
}
