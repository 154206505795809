.remove-chosen-icon() {
  background: none !important; // override Chosen's sprites
}

.font-awesomeify(@glyphCode) {
  // create pseudo-element containing given Font Awesome glyph
  &:after {
    font-family: FontAwesome;
    content: @glyphCode;

    // vertically center icon
    position: absolute;
    top: 50%;
    .translate(0, -50%);
  }
}

.chosen-container {
  font-size: inherit;
  box-shadow: inset 0px 1px 3px fade(@gray11, 30%);
  border-radius: 0.125rem;
}

.chosen-single,
.chosen-single div b,
.chosen-search,
.chosen-drop,
.chosen-results,
.chosen-results .no-results {
  background: @containerBgColor !important;
  color: @inputFgColor !important;
}

// current selection
.chosen-single {
  height: auto !important;
  @padding: 4px;
  padding-top: @padding !important;
  padding-bottom: @padding !important;

  div b {
    .remove-chosen-icon();
    .font-awesomeify('\f0d7'); // "fa fa-caret-down"
  }
}

// selection search box
.chosen-search {
  input[type='text'] {
    background: @inputBgColor !important;
    width: 100% !important;
    font-family: inherit !important;
  }

  .font-awesomeify('\f002'); // "fa fa-search"

  &:after {
    // position inside right side of input
    position: absolute;
    right: 10px;
  }
}

.chosen-results li.highlighted {
  background: @ltblue !important;
}

.chosen-container-single {
  .chosen-single {
    border: 1px solid @containerHoverBgColor;
    box-shadow: 0px 1px 3px fade(@gray11, 30%);
    .border-radius(0.1875rem);
    background: white;
    cursor: pointer;
    padding: 0.5rem;
    padding-right: 2rem;
    width: 100%;
    min-width: 3rem;
  }
}

.chosen-container-active.chosen-with-drop {
  .chosen-single,
  .chosen-drop {
    border: 1px solid fade(@inputBorderHoverColor, 66%);
  }

  .chosen-single {
    .border-radius(3px 3px 0 0);

    div b {
      .font-awesomeify('\f0d8'); // "fa fa-caret-up"
    }
  }
}

// remove chosen icons on retina displays, too
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type='text'],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type='text'],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    .remove-chosen-icon();
  }
}
